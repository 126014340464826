/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-destructuring */
<template>
  <b-card>
    <div v-if="user">
      <b-row>
        <b-col cols="12">
          <b-button
            variant="primary"
            size="md"
            class="btn-icon float-right mb-1 mr-1"
            @click="editItem"
          >
            <feather-icon icon="Edit2Icon" />
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <p>Name</p>
        </b-col>
        <b-col cols="4">
          <p>{{ user.name }}</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <p>Surname</p>
        </b-col>
        <b-col cols="4">
          <p>{{ user.surname }}</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <p>Email</p>
        </b-col>
        <b-col cols="4">
          <p>{{ user.email }}</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <p>Role</p>
        </b-col>
        <b-col cols="4">
          <p>{{ user.role }}</p>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
/* eslint-disable */

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BForm,
  BButton,
  BCard,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  name: 'ViewUser',
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BForm,
    BButton,
    BCard,
  },
  props: [
    'userId',
  ],
  data() {
    return {
      user: '',
      loading: false,
      roles: [
        {
          value: 'admin',
          text: 'Admin',
        },
        {
          value: 'partner',
          text: 'Partner',
        },
      ]
    }
  },
  beforeMount() {
    this.loadUser()
  },
  methods: {
    async loadUser() {
      const self = this

      self.loading = true
      await this.$store.dispatch('app/fetchUser', self.userId)
        .then(() => {
          self.user = self.$store.state.app.user
        })
        .catch(() => {
        })
        .finally(() => {
          self.loading = false
        })
    },
    editItem() {
      this.$router.push({ name: 'editUser', params: { userId: this.userId } })
    },
  },
}
</script>
